(function ($, site) {
  Drupal.behaviors.heroCarouselFormatterV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $formatter = $('.js-hero-carousel-formatter-v1', context);
      var $carousel;
      var defaultSlideDisplayInterval = 6000;
      var defaultSlideTransitionTime = 300;

      $formatter.each(function () {
        var $thisFormatter = $(this);
        var carouselControlsNextLabel =
          site.translations.elc_general && site.translations.elc_general.next
            ? site.translations.elc_general.next
            : 'next';
        var carouselControlsPreviousLabel =
          site.translations.elc_general && site.translations.elc_general.previous
            ? site.translations.elc_general.previous
            : 'previous';
        var $currentCarousel = $thisFormatter.find('.js-hero-carousel-formatter__container');
        var arrowsDiv = $thisFormatter.find('.js-hero-carousel-formatter__controls');
        var basicSlide = '.js-hero-carousel-formatter__container-slide';
        var $prevArrow =
          '<button class="slick-prev-button" aria-label="' +
          carouselControlsPreviousLabel +
          '"><svg role="img" class="svgicon svgicon--arrow-16 slick-prev"><use xlink:href="#arrow-16"></use></svg></button>';
        var $nextArrow =
          '<button class="slick-next-button" aria-label="' +
          carouselControlsNextLabel +
          '"><svg role="img" class="svgicon svgicon--arrow-16 slick-next"><use xlink:href="#arrow-16"></use></svg></button>';

        $carousel = $currentCarousel;

        var autoplay = $carousel.data('slides-autoplay') || false;
        var infinite = $carousel.data('slides-infinite') || false;
        var slideDisplayInterval = parseInt($currentCarousel.data('slides-speed'));
        var slideTransitionTime = parseInt($currentCarousel.data('slides-speedplay'));
        var slidesToScroll = parseInt($currentCarousel.data('slides-scroll'));

        if (isNaN(slideDisplayInterval)) {
          slideDisplayInterval = defaultSlideDisplayInterval;
        }
        if (isNaN(slideTransitionTime)) {
          slideTransitionTime = defaultSlideTransitionTime;
        }
        if (isNaN(slidesToScroll)) {
          slidesToScroll = 1;
        }

        var settings = {
          appendArrows: arrowsDiv,
          slide: basicSlide,
          infinite: infinite,
          slidesToShow: 2,
          slidesToScroll: slidesToScroll,
          autoplay: autoplay,
          autoplaySpeed: slideDisplayInterval,
          speed: slideTransitionTime,
          arrows: true,
          prevArrow: $prevArrow,
          nextArrow: $nextArrow,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        $carousel.not('.slick-initialized').slick(settings);
      });
    }

  };
})(jQuery, window.site || {});
